
html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

iframe {
    width: 100%;
    overflow: hidden;
}

.mat-navbar {
    background: #0f0e13;
}

.mat-toolbar-row {
    padding: 0 !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background: #293b7b;
    border-radius: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.f-12 {
    font-size: 12px;
}

.p-relative {
    position: relative;
}
.c-pointer{
    cursor: pointer !important;
}
.i-icon {
    position: absolute;
    right: 12px;
    top: 12px;
}
.fnt-sm.c-blue{
    color: black !important;
    font-weight: 600;
}