footer {
    background-color: #010A25;
    color: white;
    width: 100%;
    padding: 60px 0px;
    font-family: 'Montserrat', sans-serif !important;
}

img.logo_img {
    width: 270px;
    /* height: 65px; */
    object-fit: contain;
    padding: 5px;
}

.last {
    color: #a5a5a5;
}

.icon i {
    font-size: 22px;
}

.c-white {
    color: white;
}

/* .c-gray{
    color: rgb(49 167 64);
} */
.b-new {
    background-color: #141024;
}
.closebtn{
    background-color: #2b377a !important;
    color: white !important;
}
/* .docdownload{
    color: red;
    margin-top:-10px;
} */

.c-nn {
    background: #100d1f;
}

.register {
    background-color: #222222;
    width: 100%;
    padding: 15px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.transparent-input {
    color: black;
    font-size: 16px;
    padding: 10px;
    width: 100%;
}

ul.list-Links {
    display: flex;
    padding-left: 0;
    list-style: none;
}

ul.list-Links li {
    padding: 10px 20px 10px 0px;
    /* font-size: 18px; */
}

li.set_flex {
    display: flex;
    align-items: baseline;
}

.setHighlight {
    opacity: 0.9 !important;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 32px !important;
    margin-bottom: 0;
}

li.set_flex p {
    padding-left: 15px;
    opacity: 0.75;
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
}

#backtotop {
    position: fixed;
    bottom: 15px;
    right: 40px;
    z-index: 9999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 28px;
    background: #eaefff;
    color: #253b80;
    cursor: pointer;
    border: 1px solid;
    border-radius: 50%;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    font-size: 28px;
}

img.logo_img {
    padding: 0px;
}


button.btn.btn-outline.mt-3 {
    color: #fff;
    border-color: #ffffff;
}

input.form-input {
    display: flex;
    width: 260px;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #DDE8ED;
    background: #F7F8FA;
}

/* input.form-input::placeholder {
    color: gainsboro;
    opacity: .5;
} */

button.check_btn {
    display: flex;
    height: 56px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* flex: 1 0 0; */
    border-radius: 8px;
    border: 1px solid #FFF;
    background: transparent;
    color: white;
    margin-left: 20px;
    transition: 0.5s ease;
    cursor: pointer;
    outline: none !important;
}

.check_btn:hover {
    background: white;
    color: #253b80;
}

/* .social_icon {
    background: white;
    margin: 15px 15px 15px 0px;
    color: #253b80;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
} */
/* .social_icon {
    margin: 50px 0px;
} */

.social_icon img {
    margin-right: 15px;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
}

.social_icon img:hover {
    box-shadow: 0 0 45px #01FFF4;
}

.clr-span {
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 7px;
}

.terms_p {
    display: flex;
}

.terms_p a {
    color: white !important;
    margin-right: 15px;
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

li.set_flex i {
    background: rgba(245, 247, 252, 0.20);
    padding: 5px;
    border-radius: 5px;
}

.poewe {
    align-items: center;
    display: flex;
}

img.img_footer {
    width: 80px;
    margin-left: 5px;
}

.footer-btn {
    display: flex;
}

.nav {
    cursor: pointer;
}

@media only screen and (max-width:768px) {
    .register {
        background-color: #222222;
        width: 100%;
        padding: 15px 0px;
        display: block;
        padding: 10px;
    }

    .register button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .social_icon img {
        margin-right: 3px;
    }
}

@media only screen and (max-width:425px) {
    .footer-btn {
        display: block;
    }

    button.check_btn {
        display: flex;
        height: 56px;
        padding: 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #FFF;
        background: transparent;
        color: white;
        transition: 0.5s ease;
        cursor: pointer;
        outline: none !important;
        margin-top: 15px;
        margin-left: 0px;
    }
}
.footer-text-gray{
    opacity: 0.75;
    color: #FFF;
}
