a {
    color: black;
    text-decoration: none;
    word-wrap: break-word;
}

a:hover {
    text-decoration: none;
}

.align-item-center {
    align-items: center;
}

.c-blue {
    color: #007bff !important;
    cursor: pointer !important;
}

.c-green {
    color: green;
}
.c-red {
    color: red;
}
.p-relative {
    position: relative;
}

h1 {
    font-size: clamp(12px, 4vw, 34px);
}
h2 {
    font-size: clamp(12px, 4vw, 32px);
}
h3 {
    font-size: clamp(12px, 4vw, 26px);
}
h4 {
    font-size: clamp(12px, 4vw, 22px);
}
h5 {
    font-size: clamp(12px, 4vw, 20px);
}
h6 {
    font-size: clamp(12px, 4vw, 18px);
}
.font-sm {
    font-size: 12px;
    color: #ababab;
}

.font-lg {
    font-size: 16px;
}

