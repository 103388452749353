/* .gradient {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(334deg, #6b97f7, #7525e2, #f7137e);
	background-size: 180% 180%;
	animation: gradient-animation 6s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */


* {
    padding: 0;
    margin: 0;
}

.wave-ani {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    background-color: #0e4166;
    background-image: linear-gradient(to bottom, rgba(14, 65, 102, 0.86), #0e4166);
}

.logo {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1000;
    color: white;
}

.logo-wid {
    width: 34%;
}

.coming-soon-container {
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1000;
    color: white;
    font-family: Arial, sans-serif;
}

.coming-soon-container h1 {
    font-size: 3em;
    margin-bottom: 0.5em;
    font-family: cursive;
}

.coming-soon-container p {
    font-size: 1.5em;
    font-family: cursive;
}


.hr-line {
    height: 3px;
    background-color: white !important;
}

@media only screen and (max-width:1024px) {
    .coming-soon-container h1 {
        font-size: 28px;
        margin-bottom: 0.5em;
        font-family: cursive;
    }
}

@media only screen and (max-width:768px) {
    .coming-soon-container h1 {
        margin-top: 24px;
        font-size: 20px;
        margin-bottom: 0.5em;
        font-family: cursive;
    }
}

@media only screen and (max-width:425px) {
    .logo {
        position: absolute;
        top: 29%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1000;
        color: white;
    }

    .logo-wid {
        width: 75%;
    }
}